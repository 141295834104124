import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/shop-compensations', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const store = data => {
  return httpClient
    .post('/api/shop-compensations', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const show = (shopId, date) => {
  return httpClient
    .get(`/api/shop-compensations/${shopId}/${date}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const clearPreview = data => {
  return httpClient
    .post(`/api/shop-compensations/clear-preview-pdf`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const toPdf = clearId => {
  return httpClient
    .get(`/api/shop-compensations/${clearId}/pdf`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
      .get('/api/shop-compensations/form-view-models', options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}

export default { get, store, show, toPdf,clearPreview, formModel }