import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/driver-clear-cashes', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const allData = (options = {}) => {
  return httpClient
    .get('/api/driver-clear-cashes/all-data', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const store = data => {
  return httpClient
    .post('/api/driver-clear-cashes', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const successPackage = (driverId, transactionDate)=> {
  return httpClient
    .get(`/api/driver-clear-cashes/${driverId}/${transactionDate}/success-packages`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const toPdf = clearId => {
  return httpClient
    .get(`/api/driver-clear-cashes/${clearId}/pdf`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/driver-clear-cashes/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export default { allData, get, store, successPackage, toPdf, formModel }